<style scoped>
.central-text-field >>> .v-messages__message {
  color: #FFFF00 !important;
  caret-color: #FFFF00 !important;
  font-size: 16px;
}
</style>
<template>

  <form id="searchForm" ref="searchForm" method="get" action="/search" @submit.prevent="submit">
    <template>
  
    <v-row
      no-gutters
      style="flex-wrap: nowrap;"
      class="pl-3"
    >
      
      <v-col
        cols="1"
        style="min-width: 100px; max-width: 100%;"
        class="flex-grow-1 flex-shrink-0"
      >
        <div class="input--field" v-bind:class="{ full_line: SearchResultsPage}">
                    <template v-if="!hasFocus && searchString === ''">
                      <p class="placeholder">{{ placeholder }}</p>
                    </template>
                    <div
                        ref="editable"
                        class="box--spelling spelling--input"
                        spellcheck="false"
                    >
                      <div
                          :error-messages="searchErrors"
                          :contentEditable="true"
                          ref="searchInput"
                          spellcheck="false"
                          @focus="hasFocus = true"
                          @blur="hasFocus = false"
                          v-on:keyup.space="checkSpell"
                          v-on:keydown.enter="enter_skip"
                          v-on:keyup.enter="submit"
                      >
                        {{ searchString }}
                      </div>
                    </div>
                  </div>
      </v-col>
      
      <v-col
        cols="auto"
        class="flex-grow-0 flex-shrink-0"
      >
        <v-btn
                      depressed
                      height="57px"
                      color="#EE7100"
                      class="text-none search-button"
                      type="submit"

                      :width="searchWidth"
                      style="border-radius: 0px;"
                  >
                    Search
                  </v-btn>
      </v-col>
      <v-col
        v-if="SearchResultsPage"
        cols="auto"
        class="flex-grow-0 flex-shrink-0"
      >
        <v-btn
                      
                      depressed
                      class="text-none search-button"
                      color = "#206297"
                      height="57px"
                      @click='saveSearch()'
                      style="border-radius: 0px;"
                  >
                    <span v-if="this.$vuetify.breakpoint.name != 'xs'">  Save search </span>
                   <v-icon color="#fff" v-if="this.$vuetify.breakpoint.name == 'xs'"> mdi-content-save-settings-outline</v-icon>
                  </v-btn>
      </v-col>
    </v-row>
  
</template>
    <v-container v-bind:class="(SearchResultsPage)?'container--fluid':''">
      <v-row v-show="isShowAdvanced" align="center">
        <v-col
            sm="12" md="12" lg="4" xl="4">
          <v-select
              class="d-flex"
              :items="p_type"
              label="Publication type"
              solo
          ></v-select>
        </v-col>
        <v-col
            sm="12" md="12" lg="4" xl="4">
          <v-select
              :items="a_typeList"
              v-model="a_type"
              name="a_type"
              label="Access type"
              solo
          ></v-select>
        </v-col>
        <v-col
            sm="12" md="12" lg="4" xl="4">
          <v-select
              :items="p_type"
              label="Publication type"
              solo
          ></v-select>
        </v-col>
      </v-row>
      
      <v-row v-if="!SearchResultsPage" class="pt-0 mt-0">
        <v-col style="text-align: center;"><h5 style="color: yellow ">Leita will help you navigate science within the open access initiative. It enables you to find research articles and literature using advanced technology.<br/>
Leita.ai has currently opened for first phase testing, please feel free to look around. <br/>
If you become aware of any errors or want to give us feedback, please let us know at <a style="color: yellow " href="mailto:support@leita.ai">support@leita.ai</a>. Thank you!</h5></v-col>
      </v-row>
    </v-container>
  </form>
</template>


<script>

import {required} from "vuelidate/lib/validators";
import {validationMixin} from 'vuelidate';
import * as spellcheck from '../../assets/js/spellchecker.js';
import * as list from '../../assets/js/english_word_list.js';

export default {
  error: '#FFB70F',

  mixins: [validationMixin],
  name: 'SearchForm',

  data: () => ({
    string: '',
    myDictionary: '',
    searchString: '',
    lower_thresh: 0.7,
    hasFocus: false,
    error: false,
    isShowAdvanced: false,
    a_typeList: ['All', 'Open access', 'Closed access'],
    a_type: '',
    p_type: ['All', 'Article', 'Review', 'Chapter', 'Book'],
    filterForm: null,

  }),
  props: {
    SearchResultsPage: Boolean,
    obligatory: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search for open access research articles',
    },
  },
  created() {
    spellcheck.set_valid_word_list(list.english_word_list);
    this.fetchForm();
  },

  validations: {
    searchString: {required},

  },
  mounted() {

    this.$root.$off("filter_form_data");
    this.$root.$on("filter_form_data", (val) => {
      const data = JSON.parse(JSON.stringify(val));
      this.filterForm = data;
    });
    this.$root.$off("set_search_string");
    this.$root.$on("set_search_string", (val) => {

      // this.searchString = val;
      this.$refs.searchInput.innerText = val;
    });

    this.$root.$off("submit_search");
    this.$root.$on("submit_search", () => {
      this.submit();
    });


  },
  methods: {
    checkSpell() {
        let element = this.$refs.searchInput;
        let curPos = this.getCaretIndex(element);
        let text = element.innerText.replace(/(\r\n|\n|\r)/gm, "");

        let re = new RegExp(String.fromCharCode(160), "g");

        text = text.replaceAll(re, " ");
        this.searchString = text;

        element.innerText = '';
        element.focus();
      
        let parts = text.split(/(\s)/);
        if(parts[parts.length - 1] !=''){
          parts.push(" ");
        }
     
        parts.forEach((value) => {
          let oSnp = document.createElement("span");
          let cls = 'valid_txt';
          let list = [];
          switch (value) {
            case '':
              break;
            case ' ':
              oSnp.innerText = String.fromCharCode(160);
              element.appendChild(oSnp);
              break;
            default :
              if(value.trim() == '') {
                value = value.replaceAll(' ',String.fromCharCode(160));
                oSnp.innerText = value;
              } else {

                list = spellcheck.find_similar(value.trim(), this.lower_thresh)[0];
                if (list[0] != value.trim()) {
                  cls = 'err_txt';
                }

                oSnp.innerText = value.trim();
                oSnp.classList.add(cls);
              }

              element.appendChild(oSnp);
          }
        });
      this.setCaret(element, curPos);

    },
    enter_skip(ev) {
      ev.preventDefault();
    },
    submit: function () {
      this.$store.commit('search/setStartSearch', {startSearch: true});
      if (this.filterForm != null &&
          this.filterForm.searchValue != '' &&
          (this.filterForm.filterFieldsValue == null || this.filterForm.filterFieldsValue == '' || this.filterForm.filterFieldsValue == 'All Fields')) {
        let element = this.$refs.searchInput;
        element.innerText = element.innerText+" "+this.filterForm.withParam+" "+this.filterForm.searchValue;
        element.focus();
        this.filterForm.searchValue = '';
        this.filterForm.filterFieldsValue = '';
        // this.$refs.searchInput = this.filterForm.searchString;
      }
      this.checkSpell();

      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return;
      // const {searchString} = this;
      const search = {
        "searchString": this.searchString,
        'filter': this.filterForm
      };

      this.$store.dispatch('search/startSearch', search);
    },
    fetchForm: function () {
      this.$store.dispatch('search/getSearchForm', this.$route.query.hash)
          .then((data) => {
            this.searchString = data.searchString;

            this.$root.$emit("load_result_data", "");
          });
    },
    setCaret(el, pos) {

      let range = document.createRange();
      let sel = window.getSelection();
      let text = el.innerText;
      text = text.substring(0,pos);
     
      let elements = text.split(/(\s)/)
      elements = elements.filter(Boolean);
      
      let start = elements.length - 1;
      if(pos == 0) {
        range.setStart(el.lastChild, 1);
      } else {
        range.setStart(el.childNodes[start], 1);  
      }
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    },
    getCaretPosition(editableDiv) {
    let caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        let tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        let tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  },
  getCaretIndex(element) {
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  },
    showAdvanced: function () {
      if (this.isShowAdvanced == true) this.isShowAdvanced = false;
      else this.isShowAdvanced = true;
    },
    saveSearch() {
      this.$root.$emit("save_search", "");
    }
  },
  computed: {


    isActive() {
      return ((this.searchString === 0 || !!this.searchString) || this.hasFocus || this.placeholder);
    },

    searchErrors() {
      const errors = []
      if (!this.$v.searchString.$dirty) return errors
      !this.$v.searchString.required && errors.push('Search field is required.')
      return errors
    },
    searchWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 80
        case 'sm':
          return 80
        case 'md':
          return 170
        case 'lg':
          return 170
        case 'xl':
          return 170
        default :
          return 170
      }
    },
    advancedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 80
        case 'sm':
          return 80
        case 'md':
          return 92
        case 'lg':
          return 92
        case 'xl':
          return 92
        default:
          return 92
      }
    },
  },

}
</script>
